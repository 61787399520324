import { useEffect, useState } from "react"
import { MulticallContract, getMultiCallContract, getStakingContract } from "../helper/useContracts";
import { useWeb3React } from "@web3-react/core";



export const useCommonStats = (update) => {
  const context = useWeb3React();
  const { chainId } = context;

  const [stats, setStats] = useState({
    totalRewardsDistribution: 0,
    totalStake: 0,
    totalWithdrawal: 0
  });

  const mc = MulticallContract(chainId);
  const sc = getStakingContract(chainId)

  useEffect(() => {
    const fetch = async () => {
      
      const data = await mc.aggregate([
        sc.methods.totalRewardsDistribution(),
        sc.methods.totalStake(),
        sc.methods.totalWithdrawal()
      ]);

      setStats({
        totalRewardsDistribution: data[0] / Math.pow(10, 18),
        totalStake: data[1] / Math.pow(10, 18),
        totalWithdrawal: data[2] / Math.pow(10, 18)
      });
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        totalRewardsDistribution: 0,
        totalStake: 0,
        totalWithdrawal: 0

      })
    }
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
}

export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  const [stats, setStats] = useState({
    balance: 0,
    totalStake: 0,
    orderIds: '',
    totalRewardEarn: 0
  });

  const mc = MulticallContract(chainId);
  const sc = getStakingContract(chainId);
  const mcc = getMultiCallContract(chainId);


  useEffect(() => {
    const fetch = async () => {
      const data = await mc.aggregate([
        mcc.methods.getEthBalance(account),
        sc.methods.balanceOf(account),
        sc.methods.investorOrderIds(account),
        sc.methods.totalRewardEarn(account)
      ]);

      setStats({
        balance: data[0] / Math.pow(10, 18),
        totalStake: data[1] / Math.pow(10, 18),
        orderIds: data[2],
        totalRewardEarn: data[3] / Math.pow(10, 18)
      })
    }

    if (mc && account) {
      fetch();
    }
    else {
      setStats({
        balance: 0,
        totalStake: 0,
        orderIds: '',
        totalRewardEarn: 0
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}
