

export const supportNetwork = { 
    16507 : {
        name : "Genesys Testnet",
        chainId : 16507,
        rpc : "https://rpc.genesys.network",
       
    },
    'default':{
        name : "Genesys Testnet",
        chainId : 16507,
        rpc : "https://rpc.genesys.network",
       
    }

}

export const RPC_URLS = {
    16507 : "https://rpc.genesys.network"
};

