export const contract = { 
    16507 : {
        stakingAddress : "0x7CB481CE7BA82504466Bf522619Bd09f5c71D369",
        multicallAddress : "0x916F5093611378B1066BaF7013cfa86ceb9D01D5"
    },
    'default':{
        stakingAddress : "0x7CB481CE7BA82504466Bf522619Bd09f5c71D369",
        multicallAddress : "0x916F5093611378B1066BaF7013cfa86ceb9D01D5"
    }

}

